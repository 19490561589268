import URLRoutes from "urlRoutes"
import { appRext, initiativeDropdownRext, addProjectDropdownRext, homeDropdownRext, projectHistoryDetails, adminChargeCodeRext, editProjectDetailsRext, requestSaveChangesRext, requestSubmitToFinanceRext, requestUpdateFinanceRext, projectStatusDropdownRext, requestSubmitForReviewRext, requestUserPrivilageRolesRext } from "./rext"

export function requestInitiativeDropdown() {
    return initiativeDropdownRext.request({
      method: "get",
      url: URLRoutes.server.initiativesDropdown,
    })
  }

  export function requestAddProjectDropdown(PA_ID: number) {
    return addProjectDropdownRext.request({
      method: "get",
      url: URLRoutes.server.addProjectDropdown,
      urlParams: {
        PA_ID
      }
    })
  }

  export function requestHomeDropdown() {
    return homeDropdownRext.request({
      method: "get",
      url: URLRoutes.server.homeDropdown
    })
  }

  export function requestEditProject(ProjectNumber: string) {
    return editProjectDetailsRext.request({
      method: "get",
      url: URLRoutes.server.editProject,
      urlParams: {
        ProjectNumber
      }
    })
  } 

  export function requestProjectHistory(projectId: string){
    return projectHistoryDetails.request({
      url:URLRoutes.server.projectHistory,
      method:"get",
      urlParams: {
        projectId,
      }
    })
  }

  export function requestAdminChargeCode(chargeCode: string){
    return adminChargeCodeRext.request({
      url:URLRoutes.server.adminChargeCode,
      method:"get",
      urlParams: {
        chargeCode
      },
    })
  }

  export function requestSaveChanges(body: any){
    return requestSaveChangesRext.request({
      url:URLRoutes.server.saveChanges,
      method:"put",
      body
    })
  }

  export function requestSubmitToFinance(body: any){
    return requestSubmitToFinanceRext.request({
      url:URLRoutes.server.projectSubmitToFinance,
      method:"post",
      body
    })
  }

  export function requestUpdateFinance(path: string, body: any){
    return requestUpdateFinanceRext.request({
      url:URLRoutes.server.projectFinanceStatus,
      method: "put",
      urlParams: {
        path
      },
      body
    })
  }

  export function requestChargeCodeStatusDropdown() {
    return projectStatusDropdownRext.request({
      method: "get",
      url: URLRoutes.server.projectStatusDropdown
    })
  }
  
  export function requestSubmitForReview(body: any){
    return requestSubmitForReviewRext.request({
      url:URLRoutes.server.projectSubmitForReview,
      method:"post",
      body
    })
  }

  export function requestUserPrevilageRoles() {
    return requestUserPrivilageRolesRext.request({
      method: "get",
      url: URLRoutes.server.userPrevilage
    }, {
      transformation: (response: any) => {
        return response;
      }
    })
  }

  interface UserRoleResponse {
    hrId: number;
    paIds: number[];
    isAdGrpUser: boolean;
    isNonAdGrpUser: boolean;
    isAdminUser: boolean;
  }

  export function checkUserRoles(userRole: UserRoleResponse): string {
    if (userRole?.isAdminUser) {
      return "All Access";
    }
    if (userRole?.isAdGrpUser) {
      return "AD Group Access";
    }
    if (userRole?.isNonAdGrpUser) {
      return "Non AD Group Access";
    }
    if (userRole?.isNonAdGrpUser && !userRole?.isAdminUser) {
      return "Non AD Non Admin Access";
    }
    return "No Access";
  }
  


